.login-page {
    -ms-flex-align: center;
    align-items: center;
    background: #ffffff;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;

    .login-form {
        max-width: 30rem;
        background-color: #ffffff;
        box-shadow: 0px 0px 15px #999;
        padding: 2rem;

        .icon-title {
            font-size: 3rem;
            color: #ff0000;
        }

        .title {
            color: #ff0000;
        }

        .form-group {
            .icon-input {
                position: absolute;
                top: .7rem;
                left: 1.5rem;
            }

            .form-control {
                padding-left: 2rem;
                color: #000000;
                outline: none;
                border-radius: 0px;
            }
        }
        .search-option-box{
            margin-left: 15px;
        }
        .create-account {
            color: #ff0000;

            &:hover {
                color: #ff0000;
            }

            .icon-link {
                font-size: 1rem;
            }
        }

        .forgot-password {
            color: #007bff;

            &:hover {
                color: #007bff;
            }
        }

        .btn {
            outline: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .login-page {
        align-items: unset;

        .login-form {
            max-width: 100%;
        }
    }
}